import { SCREEN_EVENT_NAME } from '../constants/screen'
import type {
  BoostClickTrackerBody,
  BoostImpressionTrackerBody,
  BoostTrackerType,
  BoostViewTrackerBody,
  TRACKER_EVENT_NAME,
} from '../types'

/**
 * Note:
 * tracker event name generator logic document
 * https://playsee.atlassian.net/wiki/spaces/~712020653d38745c4e4d3cb00136950d876daa/pages/301727755/Session+Trackers+From+App+Team
 *  */

export const generateEventName = ({
  type,
  text,
  action,
}: TRACKER_EVENT_NAME) => {
  return `${type}_${text}_${action}`
}

export function generateEventTracker({
  eventName,
  sessionId,
  userId,
  parameters,
}: {
  eventName: string
  sessionId: string
  userId?: string
  parameters?: unknown
}): string {
  const tracker = {
    event_name: eventName,
    session_id: sessionId,
    user_id: userId,
    ...(parameters ? { parameters } : {}),
  }

  return JSON.stringify(tracker)
}

export function generateViewTracker({
  sessionId,
  userId,
  parameters,
}: {
  sessionId: string
  userId?: string
  parameters: Record<string, unknown>
}): string {
  const tracker = {
    event_name: SCREEN_EVENT_NAME,
    session_id: sessionId,
    user_id: userId,
    parameters,
  }

  return JSON.stringify(tracker)
}

export function generateBoostTracker({
  sessionId,
  userId,
  type,
  body,
}: {
  sessionId: string
  userId?: string
  type: BoostTrackerType
  body:
    | BoostImpressionTrackerBody
    | BoostClickTrackerBody
    | BoostViewTrackerBody
}) {
  const tracker = {
    event_name: `boost_${type}`,
    session_id: sessionId,
    user_id: userId,
    parameters: body,
  }

  return JSON.stringify(tracker)
}
