const HELP = '/help'
const EMBED = '/embed'
const ACCOUNT = '/account'
const CONTACT = '/contact'

export const ROUTE_PATH = {
  ROOT: '/',
  ENTRY: {
    INDEX: '/',
    ABOUT: '/about',
    PRODUCT: '/product',
    CONTACT: { INDEX: CONTACT, COMPLETED: `${CONTACT}/completed` },
  },
  PLANS: '/plans',
  CAREERS: '/careers',
  VERIFY_EMAIL: '/verify-email',
  DOWNLOAD: '/download',
  AUTH: '/auth',
  RESET_PASSWORD: '/account/reset-password',
  PURCHASE: '/purchase',
  ACCOUNT: {
    INDEX: ACCOUNT,
    PAYMENT_METHOD: `${ACCOUNT}/payment-method`,
    SUBSCRIPTION: `${ACCOUNT}/subscription`,
    CURRENT_PLAN: `${ACCOUNT}/current-plan`,
    RESET_PASSWORD_SUCCESS: `${ACCOUNT}/reset-password/success`,
  },
  TERMS: {
    PRIVACY_POLICY: '/privacy-policy',
    PALUP_RULES: '/palup-rules',
    USER_TERMS_OF_SERVICE: '/user-terms-of-service',
    ADVERTISING: 'advertising',
    ADVERTISING_POLICIES: 'advertising-policies',
  },
  HELP: {
    INDEX: HELP,
    GETTING_STARTED: `${HELP}/getting-started`,
    ACCOUNT: `${HELP}/account`,
    BILLING_AND_SUBSCRIPTIONS: `${HELP}/billing-and-subscriptions`,
    PRIVACY_SAFETY: `${HELP}/privacy-safety`,
    REPORT: `${HELP}/report`,
    INTELLECTUAL_PROPERTY: `${HELP}/intellectual-property`,
    DMCA: `${HELP}/dmca`,
  },
  SIDEBAR: { FEED: '/feed', DISCOVER: '/discover', MESSAGE: '/messages' },
  EMBED: {
    PAYMENT_METHOD: `${EMBED}/payment-method`,
    LANDING: {
      RECHARGE: `${EMBED}/landing/recharge`,
      PLANS: `${EMBED}/landing/plans`,
    },
    RECHARGE: `${EMBED}/recharge`,
    CONTACT_SALES: {
      INDEX: `${EMBED}/contact-sales`,
      COMPLETED: `${EMBED}/contact-sales/completed`,
    },
  },
  DEMO_PAL: '/chat-with-pals/:id',
}
