'use client'

import { Button } from '@paladise/ui/components/ui/button'
import { ThirdParty } from 'features/auth/constants'
import { useTranslations } from 'next-intl'
import { redirectThirdParty } from '../actions/redirect-third-party'
import { usePathname } from 'next/navigation'

const ThirdPartyAuth = () => {
  const t = useTranslations()
  const pathname = usePathname()

  return (
    <>
      {/* TODO: Add Facebook login */}
      {/* <Button
        onClick={() => redirectThirdParty(ThirdParty.auth.facebook, 'enterprise')}
        variant="base"
        className="h-[48px] w-full gap-2"
      >
        <img src="/images/facebook_button.svg" alt="facebook-icon" width={24} />
        {t('continue_with_facebook')}
      </Button> */}

      <Button
        onClick={() => redirectThirdParty(ThirdParty.auth.google, pathname)}
        variant="base"
        className="text-md text-label-l1 w-full gap-3"
        size="lg"
      >
        <img src="/images/google_button.svg" alt="google-icon" />
        <span>{t('continue_with_google')}</span>
      </Button>
    </>
  )
}

export default ThirdPartyAuth
