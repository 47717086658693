import { useEventCallback } from '@paladise/utils/hooks/useEventCallback'
import { useEffect, useRef } from 'react'
import type { SessionTrackerBody } from '../types'

export const useVisibilityTracker = (
  sessionTrackerBody: SessionTrackerBody | null,
  sessionId: string,
  services: {
    sessionTrackerInActive: (
      body: SessionTrackerBody & { id: string },
    ) => Promise<unknown>
    sessionTrackerResumed: (
      body: SessionTrackerBody & { id: string },
    ) => Promise<unknown>
  },
) => {
  const initialTrackerRef = useRef<boolean>(false)

  const handleResumedTracker = useEventCallback(services.sessionTrackerResumed)
  const handleInactiveTracker = useEventCallback(
    services.sessionTrackerInActive,
  )

  useEffect(() => {
    if (!sessionId || !sessionTrackerBody) return

    const body = {
      ...sessionTrackerBody,
      id: sessionId,
    }

    const handleVisibilityChange = () => {
      document.visibilityState === 'visible'
        ? handleResumedTracker(body)
        : handleInactiveTracker(body)
    }

    document.addEventListener('visibilitychange', handleVisibilityChange)

    if (!initialTrackerRef.current) {
      initialTrackerRef.current = true
      handleResumedTracker(body)
    }

    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange)
    }
  }, [
    handleInactiveTracker,
    handleResumedTracker,
    sessionId,
    sessionTrackerBody,
  ])
}
