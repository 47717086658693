import { useCallback, useEffect, useRef } from 'react'
import { useSendTracker } from './useSendTracker'

const tracker_attributes = ['data-tracker-show', 'data-tracker-click']

const TRACKER_SELECTOR = tracker_attributes.map(attr => `[${attr}]`).join(',')

/**
 * This hook is used to track elements that are added dynamically to the DOM.
 * Usage Case Eg. open Modal or Dialog etc
 */
export const useDynamicElementTracker = (pathname: string) => {
  const { handleEventTracker } = useSendTracker()
  const dynamicElementRef = useRef<MutationObserver | null>(null)
  const trackedElementsRef = useRef<WeakMap<Element, Set<string>>>(
    new WeakMap(),
  )

  const triggerTrackerEvent = useCallback(
    (element: Element, eventType: string) => {
      const trackedEvents =
        trackedElementsRef.current.get(element) || new Set<string>()
      const eventName = element.getAttribute(`data-tracker-${eventType}`)

      if (!eventName) return

      const handleEvent = () => handleEventTracker({ eventName })

      if (trackedEvents.has(eventType)) {
        element.removeEventListener(eventType, handleEvent)
        trackedEvents.delete(eventType)
      }

      /**
       * 'show' events are triggered immediately and don't need a listener
       *  other events are triggered by the user
       */
      if (eventType === 'show') {
        handleEventTracker({ eventName })
      } else {
        element.addEventListener(eventType, handleEvent)
        trackedEvents.add(eventType)
      }
      trackedElementsRef.current.set(element, trackedEvents)
    },
    [handleEventTracker],
  )

  const handleAddedNode = useCallback(
    (node: Node) => {
      if (!(node instanceof Element)) return

      const processElement = (element: Element) => {
        for (const attr of tracker_attributes) {
          if (element.hasAttribute(attr)) {
            const eventType = attr.split('-')[2]
            triggerTrackerEvent(element, eventType)
          }
        }
      }

      // handle root node
      processElement(node)

      // handle children node
      const descendants = node.querySelectorAll(TRACKER_SELECTOR)
      descendants.forEach(processElement)
    },
    [triggerTrackerEvent],
  )

  const handleRemovedNode = useCallback(
    (node: Node) => {
      if (!(node instanceof Element)) return

      const processRemoveElement = (element: Element) => {
        const trackedEvents = trackedElementsRef.current.get(element)
        if (!trackedEvents) return

        trackedEvents.forEach(eventType => {
          const eventName = element.getAttribute(`data-tracker-${eventType}`)
          if (!eventName) return

          const handleEvent = () => handleEventTracker({ eventName })
          element.removeEventListener(eventType, handleEvent)
        })

        trackedElementsRef.current.delete(element)
      }

      // remove root node
      processRemoveElement(node)

      // remove children node
      const descendants = node.querySelectorAll(TRACKER_SELECTOR)
      descendants.forEach(child => {
        if (child instanceof Element) {
          processRemoveElement(child)
        }
      })
    },
    [handleEventTracker],
  )

  const handleMutations = useCallback(
    (entries: MutationRecord[]) => {
      try {
        entries.forEach(entry => {
          if (entry.type === 'childList') {
            entry.addedNodes.forEach(handleAddedNode)
            entry.removedNodes.forEach(handleRemovedNode)
          }
        })
      } catch (error) {
        console.error('Error in handleMutations:', error)
      }
    },
    [handleAddedNode, handleRemovedNode],
  )

  useEffect(() => {
    if (!dynamicElementRef.current) {
      dynamicElementRef.current = new MutationObserver(handleMutations)
      dynamicElementRef.current.observe(document.body, {
        childList: true,
        subtree: true,
      })
    }

    return () => {
      if (dynamicElementRef.current) {
        dynamicElementRef.current.disconnect()
        dynamicElementRef.current = null
      }
      trackedElementsRef.current = new WeakMap()
    }
  }, [pathname, handleMutations, triggerTrackerEvent])
}
