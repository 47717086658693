import { useEventCallback } from '@paladise/utils/hooks/useEventCallback'
import { useTracker } from '../context'
import {
  BoostClickTrackerBody,
  BoostImpressionTrackerBody,
  BoostViewTrackerBody,
} from '../types'
import {
  generateBoostTracker,
  generateEventTracker,
  generateViewTracker,
} from '../utils/generateTracker'

export type HandleEventTracker = {
  eventName: string
  parameters?: unknown
}

export function useSendTracker() {
  const { sendTrackerMessage, sessionId, userId } = useTracker()

  const handleScreenTracker = useEventCallback(
    ({
      screenName,
      parameters,
    }: {
      screenName: string
      parameters?: Record<string, unknown>
    }) => {
      const message = generateViewTracker({
        sessionId,
        userId,
        parameters: {
          screen_name: screenName,
          screen_class: screenName,
          ...(parameters || {}),
        },
      })
      sendTrackerMessage(message)
    },
  )

  const handleEventTracker = useEventCallback(
    ({ eventName, parameters }: HandleEventTracker) => {
      const message = generateEventTracker({
        eventName,
        sessionId,
        userId,
        parameters,
      })
      sendTrackerMessage(message)
    },
  )

  const handleBoostTracker = useEventCallback(
    ({
      type,
      body,
    }: {
      type: 'impression' | 'click' | 'view'
      body:
        | BoostImpressionTrackerBody
        | BoostClickTrackerBody
        | BoostViewTrackerBody
    }) => {
      const message = generateBoostTracker({
        sessionId,
        userId,
        type,
        body,
      })
      sendTrackerMessage(message)
    },
  )

  return { handleScreenTracker, handleEventTracker, handleBoostTracker }
}
