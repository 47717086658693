'use client'

import { onelinkUrlByEnv } from 'config/constants'
import React from 'react'
import { getSelectorsByUserAgent } from 'react-device-detect'
import { useDevice } from 'store/provider/device-provider'
import { env } from 'store/server-context/static'
import { HASH_KEY } from 'utils/hooks/useHash'

const ONELINK_URL = onelinkUrlByEnv(env.ENV)

export const clickDownloadLink = () => {
  // check if html has class 'playsee-desktop'
  if (document.documentElement.classList.contains('playsee-desktop')) {
    window.location.hash = `#${HASH_KEY.DOWNLOAD_APP}`
  } else {
    const result = getSelectorsByUserAgent(navigator.userAgent)
    window.open(
      ONELINK_URL,
      result.isAndroid ? '_self' : '_blank',
      'noreferrer noopenner nofollow',
    )
  }
}

const DownloadLink = (props: Omit<React.ComponentProps<'a'>, 'href'>) => {
  const device = useDevice()
  const [_target, setTarget] = React.useState('_blank')

  React.useEffect(() => {
    const result = getSelectorsByUserAgent(navigator.userAgent)
    setTarget(result.isAndroid ? '_self' : '_blank')
  }, [])

  let href = `#${HASH_KEY.DOWNLOAD_APP}`
  let target = props.target
  if (device !== 'desktop') {
    href = ONELINK_URL
    target = _target
  }

  return <a href={href} {...props} target={target} />
}

export default DownloadLink
