import { useEffect } from 'react'
import { getScreenPageName } from '../utils/getScreenPageName'
import { useSendTracker } from './useSendTracker'

export const usePageNameTracker = (pathname: string) => {
  const { handleScreenTracker } = useSendTracker()

  useEffect(() => {
    const screenName = getScreenPageName({ pathname })
    handleScreenTracker({ screenName })
  }, [pathname, handleScreenTracker])
}
