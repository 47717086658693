import { clsx, type ClassValue } from 'clsx'
import { extendTailwindMerge } from 'tailwind-merge'
import { defaultConfig, tv } from 'tailwind-variants'
import { theme } from '../../theme'

const mergeConfig: Parameters<typeof extendTailwindMerge>[0] = {
  extend: {
    classGroups: {
      'font-size': [{ text: Object.keys(theme?.fontSize!) }],
    },
  },
}

defaultConfig.twMergeConfig = {
  ...mergeConfig,
}

const customMerge = extendTailwindMerge({
  ...mergeConfig,
})

export const bubbleAnimation = tv({
  base: 'animate-in zoom-in-50 ease-in-bubble [animation-duration:var(--duration,0)]',
  variants: {
    from: {
      left: 'origin-bottom-left',
      right: 'origin-bottom-right',
    },
  },
})

export function cn(...inputs: ClassValue[]) {
  return customMerge(clsx(inputs))
}
