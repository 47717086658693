import type { ChatHistory, ChatListItem } from 'features/chat/type'
import { type Fetch } from 'lib/fetch/type'
import { IRespV2, type IResp } from 'type/common'

export const getChatHistory = async (
  fetcher: Fetch,
  user_id: string,
): Promise<IResp<ChatHistory[]>> => {
  const res = await fetcher(
    fetcher.API.CHAT + `/web/v1/chat/chat_history_list/${user_id}`,
  )

  return res
}

export const deleteChatHistory = async (
  fetcher: Fetch,
  data: {
    avatar_ids: string[]
    user_id: string
  },
): Promise<IRespV2> => {
  const res = await fetcher(fetcher.API.CHAT + `/web/v1/chat/delete_history`, {
    method: 'POST',
    body: data,
  })

  return res
}

export const checkCredit = async (
  fetcher: Fetch,
  userId: string,
): Promise<IRespV2> => {
  const res = await fetcher(
    fetcher.API.CHAT + `/web/v1/payment/credit_check/${userId}`,
    {
      method: 'POST',
    },
  )

  return res
}

export const getLastChatList = async (
  fetcher: Fetch,
  query: {
    page: number
    page_size: number
  },
): Promise<IRespV2<ChatListItem[]>> => {
  const res = await fetcher(fetcher.API.GW + `/v1/users/me/chats/history`, {
    method: 'GET',
    query,
  })

  return res
}

export const searchChatHistory = async (
  fetcher: Fetch,
  query: {
    keyword: string
    page: number
    page_size: number
  },
): Promise<IRespV2<ChatListItem[]>> => {
  const res = await fetcher(
    fetcher.API.GW + `/v1/users/me/chats/history/messages/search`,
    {
      method: 'GET',
      query,
    },
  )

  return res
}
