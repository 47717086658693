'use client'

import { cn } from '@paladise/ui/lib/utils'
import { ROUTE_PATH } from 'config/routerPath'
import { usePathname } from 'next/navigation'
import React from 'react'

const subscribeMedia = (listener: () => void) => {
  window.matchMedia('(hover: hover)').addEventListener('change', listener)
  return () => {
    window.matchMedia('(hover: hover)').removeEventListener('change', listener)
  }
}
const snapshot = () => {
  return window.matchMedia('(hover: hover)').matches
}
const serverSnapshot = () => {
  return false
}

const LandingHeader = ({ children }: { children: React.ReactNode }) => {
  const pathname = usePathname()
  const isHover = React.useSyncExternalStore(
    subscribeMedia,
    snapshot,
    serverSnapshot,
  )

  const home = pathname === ROUTE_PATH.ENTRY.INDEX

  return (
    <div
      className={cn(
        'h-mobile-fixed-top min768:h-fixed-top min768:px-12 fixed top-0 z-30 w-full px-3 transition-colors',
        home ? isHover && 'hover:bg-white' : 'bg-white',
      )}
    >
      {children}
    </div>
  )
}

export default LandingHeader
