import { CDN_URL } from '@paladise/config/constants'
import { cn } from '@paladise/ui/lib/utils'

const Logo = ({
  className,
  width = 80,
  height = 36,
  onClick,
}: {
  className?: string
  width?: number
  height?: number
  onClick?: (e: React.MouseEvent<HTMLImageElement, MouseEvent>) => void
}) => {
  return (
    <img
      src={`${CDN_URL}/palup/consumer/logo_v2_1.svg`}
      alt="PalUp Logo"
      width={width}
      height={height}
      style={{
        '--width': `${width}px`,
        '--height': `${height}px`,
      }}
      className={cn('h-[--height] w-[--width]', className)}
      onClick={onClick}
    />
  )
}

export default Logo
