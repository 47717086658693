import(/* webpackMode: "eager" */ "/app/apps/consumer/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/app/apps/consumer/node_modules/next/font/google/target.css?{\"path\":\"src/app/layout.tsx\",\"import\":\"Poppins\",\"arguments\":[{\"subsets\":[\"latin\",\"latin-ext\"],\"variable\":\"--font-poppins\",\"weight\":[\"700\"]}],\"variableName\":\"poppins\"}");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/apps/consumer/src/components/Debugger.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/apps/consumer/src/components/qrcode/QrcodeWrapper.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/apps/consumer/src/components/TrackerRegister.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/apps/consumer/src/features/account/components/SyncUserInfo.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/apps/consumer/src/features/auth/components/AuthDialog.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/apps/consumer/src/features/consent/components/ConsentBanner.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["DeviceProvider"] */ "/app/apps/consumer/src/store/provider/device-provider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["StaticServerContext"] */ "/app/apps/consumer/src/store/server-context/static.ts");
;
import(/* webpackMode: "eager" */ "/app/node_modules/@next/third-parties/dist/google/ga.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/@next/third-parties/dist/google/gtm.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/@next/third-parties/dist/ThirdPartyScriptEmbed.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/node_modules/next-intl/dist/esm/shared/NextIntlClientProvider.js");
;
import(/* webpackMode: "eager", webpackExports: ["__esModule","default"] */ "/app/node_modules/nextjs-toploader/dist/index.js");
;
import(/* webpackMode: "eager", webpackExports: ["NuqsAdapter"] */ "/app/node_modules/nuqs/dist/adapters/next/app.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/slick-carousel/slick/slick-theme.css");
;
import(/* webpackMode: "eager" */ "/app/node_modules/slick-carousel/slick/slick.css");
;
import(/* webpackMode: "eager", webpackExports: ["Toaster"] */ "/app/node_modules/sonner/dist/index.mjs");
;
import(/* webpackMode: "eager" */ "/app/packages/ui/src/tailwind.css");
