'use client'
import React, { useEffect } from 'react'
import { useAccountStore } from 'store/account-store'
import { session } from 'store/server-context/static'

function SyncUserInfo() {
  const syncUserInfo = useAccountStore.use.syncUserInfo()
  useEffect(() => {
    syncUserInfo()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [session.userId])
  return null
}

export default SyncUserInfo
