'use client'

import { TrackerProvider } from '@paladise/tracker/context'
import { useAutoBindTracker } from '@paladise/tracker/hooks/useAutoBindTracker'
import { useDynamicElementTracker } from '@paladise/tracker/hooks/useDynamicElementTracker'
import { usePageNameTracker } from '@paladise/tracker/hooks/usePageNameTracker'
import { useVisibilityTracker } from '@paladise/tracker/hooks/useVisibilityTracker'
import type { SessionTrackerBody } from '@paladise/tracker/types'
import { sessionTrackerInActive, sessionTrackerResumed } from 'api/tracker'
import { clientFetch } from 'lib/fetch/client'
import { usePathname } from 'next/navigation'
import { env, session, v2Token } from 'store/server-context/static'
import { useWebsocket } from 'utils/hooks/useWebsocket'

const TrackerInitializer = () => {
  const pathname = usePathname()

  // initialize auto bind tracker
  useAutoBindTracker(pathname)

  // initialize dynamic element tracker
  useDynamicElementTracker(pathname)

  // initialize page name tracker
  usePageNameTracker(pathname)

  return null
}

const TrackerRegister = ({
  sessionTrackerBody,
  sessionId,
  children,
}: {
  sessionTrackerBody: SessionTrackerBody | null
  sessionId: string
  children: React.ReactNode
}) => {
  const [ws, sendMessage] = useWebsocket(env.HOST_TRACKER, v2Token, {
    enabled: !!sessionTrackerBody,
  })
  // initialize page visibility tracker
  useVisibilityTracker(sessionTrackerBody, sessionId, {
    sessionTrackerInActive: body => sessionTrackerInActive(clientFetch, body),
    sessionTrackerResumed: body => sessionTrackerResumed(clientFetch, body),
  })

  return (
    <TrackerProvider
      value={{
        sendTrackerMessage: sendMessage,
        sessionId,
        userId: session.userId,
      }}
    >
      {ws && <TrackerInitializer />}
      {children}
    </TrackerProvider>
  )
}

export default TrackerRegister
