import {
  IProduct,
  CheckoutResponse,
  ISubscription,
  CheckoutResponseErrorState,
  SourceEnum,
} from 'features/payment/type'
import {
  IProducts,
  IProduct as IPlan,
  IContactSalesReq,
  ICancelFeedbackReq,
} from 'features/purchase/constants'
import type { Fetch } from 'lib/fetch/type'
import { ApiCode, type IRespV2 } from 'type/common'
import { generateParams } from 'utils/generate-params'

export const getAccountCredits = async (
  fetcher: Fetch,
): Promise<IRespV2<{ account_id: string; credit: number }>> => {
  const res = await fetcher(fetcher.API.GW + '/v1/user/credit', {
    method: 'GET',
  })

  return res
}

export const updateUserSubscription = async (
  fetcher: Fetch,
  subscription_id: string,
  cancel_at_period_end: boolean,
): Promise<boolean> => {
  try {
    const res: IRespV2<{ message: string }> = await fetcher(
      fetcher.API.GW +
        `/v1/user/subscriptions/${subscription_id}/cancel-at-period-end`,
      {
        method: 'PATCH',
        body: { cancel_at_period_end },
      },
    )

    return res.code === ApiCode.success
  } catch (error) {
    return false
  }
}

export const getCreditsProducts = async (
  fetcher: Fetch,
): Promise<IProduct[] | null> => {
  const params = generateParams({
    source: SourceEnum.stripe,
    group: 'personal',
  })

  try {
    const res: IRespV2<{ products: IProduct[] }> = await fetcher(
      fetcher.API.GW + `/v1/products/credits?${params}`,
      {
        method: 'GET',
      },
    )

    return res?.data.products
  } catch (error) {
    return null
  }
}

export const createUserCheckout = async (
  fetcher: Fetch,
  product_id: string,
  subscription_id?: string,
  session_id?: string,
): Promise<CheckoutResponse | string> => {
  try {
    const res: IRespV2<CheckoutResponse> = await fetcher(
      fetcher.API.GW + `/v1/user/checkout`,
      {
        method: 'POST',
        body: { product_id, subscription_id, session_id },
      },
    )

    if (res.code === ApiCode.success) {
      return res.data
    }
    return CheckoutResponseErrorState.failed
  } catch (error) {
    if (
      typeof error === 'object' &&
      error !== null &&
      'code' in error &&
      'message' in error
    ) {
      const err = error as { code: number; message: string }
      if (err.code === 409 && err.message === 'checkout session processed') {
        return CheckoutResponseErrorState.checkoutSessionProcesse
      }
    }
    return CheckoutResponseErrorState.failed
  }
}

export const getUserOrder = async (
  fetcher: Fetch,
  order_id: string,
): Promise<number | null> => {
  try {
    const res: IRespV2<{ status: number }> = await fetcher(
      fetcher.API.GW + `/v1/orders/${order_id}`,
      {
        method: 'GET',
      },
    )
    if (res.code === ApiCode.success) {
      return res.data.status
    }
    return null
  } catch (error) {
    return null
  }
}

export const getSubscriptionsProducts = async (
  fetcher: Fetch,
): Promise<IPlan[] | []> => {
  try {
    const res: IRespV2<IProducts> = await fetcher(
      fetcher.API.GW + `/v1/products/subscriptions?source=stripe`,
      {
        method: 'GET',
      },
    )

    return res.data.products
  } catch (error) {
    return []
  }
}

export const getUserSubscriptionList = async (
  fetcher: Fetch,
): Promise<ISubscription[]> => {
  try {
    const res: IRespV2<{ subscriptions: ISubscription[] }> = await fetcher(
      fetcher.API.GW + `/v1/user/subscriptions`,
      {
        method: 'GET',
      },
    )

    return res.data.subscriptions
  } catch (error) {
    return []
  }
}

export const getUserSubscription = async (
  fetcher: Fetch,
): Promise<ISubscription | null> => {
  try {
    const res: IRespV2<ISubscription> = await fetcher(
      fetcher.API.GW + `/v1/users/me/subscriptions/personal`,
      {
        method: 'GET',
      },
    )

    return res.data
  } catch (error) {
    return null
  }
}

export const PostContactSales = async (
  fetcher: Fetch,
  body: IContactSalesReq,
): Promise<boolean> => {
  try {
    const res: IRespV2<null> = await fetcher(
      fetcher.API.SESSION + `/web/v1/purchase/contact_sales`,
      {
        method: 'POST',
        body,
      },
    )

    return res.code === ApiCode.success
  } catch (error) {
    return false
  }
}

export const GetCancelReasons = async (fetcher: Fetch): Promise<string[]> => {
  try {
    const res: IRespV2<string[]> = await fetcher(
      fetcher.API.SESSION + `/web/v1/subscription/cancel_reasons`,
      {
        method: 'GET',
      },
    )
    return res.data
  } catch (error) {
    return []
  }
}

export const PostCancelFeedback = async (
  fetcher: Fetch,
  body: ICancelFeedbackReq,
): Promise<boolean> => {
  try {
    const res: IRespV2<null> = await fetcher(
      fetcher.API.SESSION + `/web/v1/subscription/cancel_feedback`,
      {
        method: 'POST',
        body,
      },
    )

    return res.code === ApiCode.success
  } catch (error) {
    return false
  }
}
