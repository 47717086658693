'use client'

import { CONSENT_COOKIE_NAME } from '@paladise/config/constants'
import { useEventCallback } from '@paladise/utils/hooks/useEventCallback'
import Cookies from 'js-cookie'
import { useTranslations } from 'next-intl'
import Link from 'next/link'
import { usePathname } from 'next/navigation'
import React from 'react'

const ConsentBanner = ({
  cookie,
  tracker,
}: {
  cookie: string | undefined
  tracker: boolean
}) => {
  const t = useTranslations()
  const [isToastVisible, setToastVisible] = React.useState(
    cookie ? cookie !== 'true' : true,
  )
  const resizeRef = React.useRef<ResizeObserver | null>(null)
  const timeoutRef = React.useRef<NodeJS.Timeout | null>(null)
  const pathname = usePathname()

  const handleCloseToast = () => {
    setToastVisible(false)
    Cookies.set(CONSENT_COOKIE_NAME, 'true')
  }

  const containerRef = useEventCallback((node: HTMLDivElement | null) => {
    if (resizeRef.current) {
      resizeRef.current.disconnect()
    }
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current)
      timeoutRef.current = null
    }
    if (!node) {
      document.documentElement.style.setProperty(
        '--spacing-consent-height',
        '0px',
      )
      return
    }

    function setHeight(height: number) {
      document.documentElement.style.setProperty(
        '--spacing-consent-height',
        `${height}px`,
      )
    }

    setHeight(node.clientHeight)

    resizeRef.current = new ResizeObserver(entry => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current)
        timeoutRef.current = null
      }

      // debounce
      // prevent flickering in some Android chrome
      timeoutRef.current = setTimeout(() => {
        setHeight(entry[0].borderBoxSize[0].blockSize)
      }, 50)
    })
    resizeRef.current.observe(node)
  })

  if (!isToastVisible || pathname === '/embed') {
    return null
  }

  return (
    <div
      ref={containerRef}
      className="z-toast bg-floating-still text-label-still-l1 min768:bottom-[80px] min768:left-4 min768:w-[332px] min768:rounded-xl pointer-events-auto fixed bottom-0 left-0 flex w-full items-center px-[12px] py-[8px] text-[12px]"
    >
      <p className="border-label-still-l3 border-r-[1px] pr-[12px]">
        {tracker
          ? t.rich('cookies_policy_hint', {
              t1: () => (
                <Link
                  href="/privacy-policy"
                  className="font-semibold"
                  target="_blank"
                >
                  {t('common.cookie_policy')}
                </Link>
              ),
            })
          : t('cookies_usage_hint')}
      </p>
      <button
        className="ml-[12px] shrink-0 px-[8px] font-semibold"
        onClick={handleCloseToast}
      >
        {t('ok')}
      </button>
    </div>
  )
}

export default ConsentBanner
