import type { Config } from 'tailwindcss'

export const theme: Config['theme'] = {
  fontSize: {
    '6xl': ['var(--font-size-6xl)', 'var(--font-size-6xl--line-height)'],
    '3xl': ['var(--font-size-3xl)', 'var(--font-size-3xl--line-height)'],
    '2xl': ['var(--font-size-2xl)', 'var(--font-size-2xl--line-height)'],
    xl: ['var(--font-size-xl)', 'var(--font-size-xl--line-height)'],
    lg: ['var(--font-size-lg)', 'var(--font-size-lg--line-height)'],
    md: ['var(--font-size-md)', 'var(--font-size-md--line-height)'],
    sm: ['var(--font-size-sm)', 'var(--font-size-sm--line-height)'],
    xs: ['var(--font-size-xs)', 'var(--font-size-xs--line-height)'],
    base: ['var(--font-size-md)', 'var(--font-size-md--line-height)'],
    xlTitle: [
      'var(--font-size-xl-title)',
      {
        lineHeight: 'var(--line-height-xl-title)',
        fontWeight: 'var(--font-weight-xl-title)',
        letterSpacing: 'var(--letter-spacing-xl-title)',
      },
    ],
    bigTitle: [
      'var(--font-size-big-title)',
      {
        lineHeight: 'var(--line-height-big-title)',
        fontWeight: 'var(--font-weight-big-title)',
      },
    ],
    midTitle: [
      'var(--font-size-mid-title)',
      {
        lineHeight: 'var(--line-height-mid-title)',
        fontWeight: 'var(--font-weight-mid-title)',
      },
    ],
    title: [
      'var(--font-size-title)',
      {
        lineHeight: 'var(--line-height-title)',
        fontWeight: 'var(--font-weight-title)',
      },
    ],
    smallTitle: [
      'var(--font-size-small-title)',
      {
        lineHeight: 'var(--line-height-small-title)',
        fontWeight: 'var(--font-weight-small-title)',
      },
    ],
    header: [
      'var(--font-size-header)',
      {
        lineHeight: 'var(--line-height-header)',
        fontWeight: 'var(--font-weight-header)',
      },
    ],
    subtitle: [
      'var(--font-size-subtitle)',
      {
        lineHeight: 'var(--line-height-subtitle)',
        fontWeight: 'var(--font-weight-subtitle)',
      },
    ],
    mainText: [
      'var(--font-size-main-text)',
      {
        lineHeight: 'var(--line-height-main-text)',
        fontWeight: 'var(--font-weight-main-text)',
      },
    ],
    largeBody: [
      'var(--font-size-large-body)',
      {
        lineHeight: 'var(--line-height-large-body)',
        fontWeight: 'var(--font-weight-large-body)',
      },
    ],
    body: [
      'var(--font-size-body)',
      {
        lineHeight: 'var(--line-height-body)',
        fontWeight: 'var(--font-weight-body)',
      },
    ],
    callout: [
      'var(--font-size-callout)',
      {
        lineHeight: 'var(--line-height-callout)',
        fontWeight: 'var(--font-weight-callout)',
      },
    ],
    caption: [
      'var(--font-size-caption)',
      {
        lineHeight: 'var(--line-height-caption)',
        fontWeight: 'var(--font-weight-caption)',
      },
    ],
    mini: [
      'var(--font-size-mini)',
      {
        lineHeight: 'var(--line-height-mini)',
        fontWeight: 'var(--font-weight-mini)',
      },
    ],
    quote: [
      'var(--font-size-quote)',
      {
        lineHeight: 'var(--line-height-quote)',
        fontWeight: 'var(--font-weight-quote)',
      },
    ],
  },
  lineHeight: {
    '6xl': 'var(--font-size-6xl--line-height)',
    '3xl': 'var(--font-size-3xl--line-height)',
    '2xl': 'var(--font-size-2xl--line-height)',
    xl: 'var(--font-size-xl--line-height)',
    lg: 'var(--font-size-lg--line-height)',
    md: 'var(--font-size-md--line-height)',
    sm: 'var(--font-size-sm--line-height)',
    xs: 'var(--font-size-xs--line-height)',
    base: 'var(--font-size-md--line-height)',
  },
  screens: {
    min360: '360px',
    min480: '480px',
    min560: '560px',
    min600: '600px',
    min720: '720px',
    min768: '768px',
    min900: '900px',
    min1080: '1080px',
    min1024: '1024px',
    min1280: '1280px',
    min1440: '1440px',
  },
  extend: {
    fontFamily: {
      poppins: ['var(--font-poppins)'],
    },
    colors: {
      'palup-brand-yellow': 'var( --color-palup-brand-yellow)',
      'palup-black': 'var( --color-palup-black)',
      'palup-white': 'var( --color-palup-white)',
      'palup-blue': 'var( --color-palup-blue)',
      'palup-green': 'var( --color-palup-green)',
      'palup-orange': 'var( --color-palup-orange)',
      floating: 'var(--color-floating)',
      alert: 'var(--color-alert)',
      overlay: 'var(--color-overlay)',
      'overlay-light': 'var(--color-overlay-light)',
      'overlay-still': 'var(--color-overlay-still)',
      'overlay-thick': 'var(--color-overlay-thick)',
      'embed-payment-bg': 'var(--color-embed-payment-bg)',

      l: {
        l1: 'var(--color-l-l1)',
        'l1-keep': 'var(--color-l-l1-keep)',
        l2: 'var(--color-l-l2)',
        l3: 'var(--color-l-l3)',
      },
      button: {
        p1: 'var(--color-button-p1)',
        'p1-hover': 'var(--color-button-p1-hover)',
        p2: 'var(--color-button-p2)',
        'p2-hover': 'var(--color-button-p2-hover)',
        p3: 'var(--color-button-p3)',
        'p3-hover': 'var(--color-button-p3-hover)',
        'on-primary': 'var(--color-button-on-primary)',
        'on-primary-hover': 'var(--color-button-on-primary-hover)',
      },
      gray: {
        gray1: 'var(--color-gray-gray1)',
        gray2: 'var(--color-gray-gray2)',
        gray3: 'var(--color-gray-gray3)',
        gray4: 'var(--color-gray-gray4)',
        gray5: 'var(--color-gray-gray5)',
      },
      background: {
        b1: 'var(--color-background-b1)',
        '1st': 'var(--color-background-1st)',
        '1st-30blur': 'var(--color-background-1st-30blur)',
        '2nd': 'var(--color-background-2nd)',
        'palup-white': 'var(--color-palup-white)',
        'palup-black': 'var(--color-palup-black)',
      },
      'background-elevated': {
        b1: 'var(--color-background-elevated-b1)',
        '1st': 'var(--color-background-elevated-1st)',
        '2nd': 'var(--color-background-elevated-2nd)',
      },
      glass: {
        'alpha-0': 'var(--color-glass-alpha-0)',
        tips: 'var(--color-glass-tips)',
        options: 'var(--color-glass-options)',
        separators: 'var(--color-glass-separators)',
        'ios-20blur': 'var(--color-ios-20blur)',
        android: 'var(--color-android)',
      },

      label: {
        l1: 'var(--color-label-l1)',
        l2: 'var(--color-label-l2)',
        l3: 'var(--color-label-l3)',
      },
      primary: {
        p1: 'var(--color-primary-p1)',
        p2: 'var(--color-primary-p2)',
        'p2-hover': 'var(--color-primary-p2-hover)',
        'on-primary': 'var(--color-primary-on-primary)',
      },
      secondary: {
        p1: 'var(--color-secondary-p1)',
        p2: 'var(--color-secondary-p2)',
        'on-secondary': 'var(--color-secondary-on-secondary)',
      },
      third: {
        p1: 'var(--color-third-p1)',
        p2: 'var(--color-third-p2)',
        'on-third': 'var(--color-third-on-third)',
      },
      playseeGray: {
        gray1: 'var(--color-playseeGray-gray1)',
        gray2: 'var(--color-playseeGray-gray2)',
        gray3: 'var(--color-playseeGray-gray3)',
        gray4: 'var(--color-playseeGray-gray4)',
        gray5: 'var(--color-playseeGray-gray5)',
      },

      'label-still': {
        l1: 'var(--color-label-still-l1)',
        l2: 'var(--color-label-still-l2)',
        l3: 'var(--color-label-still-l3)',
      },
      'primary-still': {
        p1: 'var(--color-primary-still-p1)',
        p2: 'var(--color-primary-still-p2)',
        'on-primary': 'var(--color-primary-still-on-primary)',
      },
      'secondary-still': {
        p1: 'var(--color-secondary-still-p1)',
        p2: 'var(--color-secondary-still-p2)',
        'on-secondary': 'var(--color-secondary-still-on-secondary)',
      },
      'third-still': {
        p1: 'var(--color-third-still-p1)',
        p2: 'var(--color-third-still-p2)',
        'on-third': 'var(--color-third-still-on-third)',
      },
      'playseeGray-still': {
        gray1: 'var(--color-playseeGray-still-gray1)',
        gray2: 'var(--color-playseeGray-still-gray2)',
        gray3: 'var(--color-playseeGray-still-gray3)',
        gray4: 'var(--color-playseeGray-still-gray4)',
        gray5: 'var(--color-playseeGray-still-gray5)',
      },
      'background-still': {
        b1: 'var(--color-background-still-b1)',
        '1st': 'var(--color-background-still-1st)',
        '2nd': 'var(--color-background-still-2nd)',
      },
      'background-elevated-still': {
        b1: 'var(--color-background-still-elevated-b1)',
        '1st': 'var(--color-background-still-elevated-1st)',
        '2nd': 'var(--color-background-still-elevated-2nd)',
      },
      'floating-still': 'var(--color-floating-still)',
      'alert-still': 'var(--color-alert-still)',

      'glass-special': {
        'alpha-0': 'var(--color-glass-special-alpha-0)',
        tips: 'var(--color-glass-special-tips)',
        options: 'var(--color-glass-special-options)',
        separators: 'var(--color-glass-special-separators)',
      },
      'glass-ios': {
        '20blur-light': 'var(--color-glass-ios-20blur-light)',
        '20blur-still': 'var(--color-glass-ios-20blur-still)',
      },
      'glass-android': {
        light: 'var(--color-glass-android-light)',
        still: 'var(--color-glass-android-still)',
      },
      message: {
        success: 'var(--color-message-success)',
        error: 'var(--color-message-error)',
        warning: 'var(--color-message-warning)',
        info: 'var(--color-message-info)',
      },
    },
    spacing: {
      'fixed-top': 'var(--spacing-fixed-top)',
      'mobile-fixed-top': 'var(--spacing-mobile-fixed-top)',
    },
    keyframes: {
      'spinner-rotate': {
        from: {
          transform: 'translate(-50%, -50%) rotate(0deg)',
        },
        to: {
          transform: 'translate(-50%, -50%) rotate(360deg)',
        },
      },
      'fade-in': {
        from: { opacity: '0' },
        to: { opacity: '1' },
      },
      'fade-out': {
        from: { opacity: '1' },
        to: { opacity: '0' },
      },
      'bottom-enter': {
        from: {
          transform: 'translateY(100%)',
        },
        to: { transform: 'translateY(0)' },
      },
      'bottom-exist': {
        from: {
          transform: 'translateY(0)',
        },
        to: {
          transform: 'translateY(100%)',
        },
      },
      'toast-enter': {
        from: {
          transform: 'translate3d(0,calc(var(--factor) * -200%),0) scale(.6)',
          opacity: '0.5',
        },
        to: { transform: 'translate3d(0,0,0) scale(1)', opacity: '1' },
      },
      'toast-exist': {
        from: {
          transform:
            'translate3d(0,calc(var(--factor) * -150%),-1px) scale(.6)',
          opacity: '0',
        },
        to: {
          transform: 'translate3d(0,0,-1px) scale(1)',
          opacity: '1',
        },
      },
      'menu-in': {
        from: {
          transform: 'translateX(-100%)',
        },
        to: { transform: 'translateX(0)' },
      },
      'menu-out': {
        from: {
          transform: 'translateX(0)',
        },
        to: { transform: 'translateX(-100%)' },
      },
    },
    animation: {
      'spinner-rotate': 'spinner-rotate 1s linear infinite',
      'fade-in': 'fade-in 0.5s cubic-bezier(0.32, 0.72, 0, 1) forwards',
      'fade-out': 'fade-out 0.5s cubic-bezier(0.32, 0.72, 0, 1)',
      'bottom-enter':
        'bottom-enter 0.5s cubic-bezier(0.32, 0.72, 0, 1) forwards',
      'bottom-exist': 'bottom-exist 0.5s cubic-bezier(0.32, 0.72, 0, 1)',
      'toast-enter': 'toast-enter 0.35s forwards cubic-bezier(.21,1.02,.73,1)',
      'toast-exist': 'toast-exist 0.4s forwards cubic-bezier(.06,.71,.55,1)',
      'image-fade-in': 'fade-in 0.4s cubic-bezier(0.32, 0.72, 0, 1) forwards',
      'menu-in': 'menu-in 0.3s cubic-bezier(0.32, 0.72, 0, 1) forwards',
      'menu-out': 'menu-out 0.3s cubic-bezier(0.32, 0.72, 0, 1) forwards',
    },
    animationTimingFunction: {
      'in-bubble': 'cubic-bezier(0.417,1.227,0.337,1.101)',
    },
    boxShadow: {
      floating: 'var(--shadow-floating)',
      'floating-button': 'var(--shadow-floating-button)',
      'floating-dropdown': 'var(--shadow-floating-dropdown)',
      toast: 'var(--shadow-toast)',
      card: 'var(--shadow-card)',
    },
    dropShadow: {
      dropdown: [
        '0px 0px 12px rgba(0, 0, 0, 0.07)',
        '0px 0px 4px rgba(0, 0, 0, 0.04)',
      ],
      image: [
        '0px 1px 3px rgba(0, 0, 0, 0.12)',
        '0px 0px 2px rgba(0, 0, 0, 0.12)',
      ],
    },
    zIndex: {
      header: 'var(--z-index-header)',
      'mobile-nav': 'var(--z-index-mobile-nav)',
      modal: 'var(--z-index-modal)',
      'modal-1': 'var(--z-index-modal-1)',
      alert: 'var(--z-index-alert)',
      'alert-1': 'var(--z-index-alert-1)',
      popover: 'var(--z-index-popover)',
      'popover-1': 'var(--z-index-popover-1)',
      download: 'var(--z-index-download)',
      toast: 'var(--z-index-toast)',
    },
  },
}
