export enum SourceEnum {
  stripe = 'stripe',
  appstore = 'appstore',
}
export interface ISubscription {
  id: string
  source: SourceEnum
  group: string
  product_id: string
  plan: string
  cancel_at_period_end: boolean
  status: string
  expired_at: number
  appstore_transaction_id: string
  renew_plan: string
  renew_plan_id: number
  renew_product_id: number
}

export interface Purchase {
  id: string
  avatar_id: string
  purchase_id: string
  expired_at: number
}

export enum ProductCategoryEnum {
  credit = 'credit',
  subscription = 'subscription',
}

export interface IProduct {
  id: string
  name: string
  currency: string
  amount: string
  source?: SourceEnum
  credit: string
  discount_label: string
}

export interface CheckoutResponse {
  order_id: string
  stripe_subscription_id?: string
}

export const CheckoutResponseErrorState = {
  checkoutSessionProcesse: 'checkoutSessionProcesse',
  failed: 'failed',
} as const

export const orderState = {
  Processing: 1,
  success: 2,
  failed: 3,
} as const

export interface IInvoiceResponse {
  invoices: IInvoice[]
  next_cursor: string
  has_more: boolean
}

export interface IInvoice {
  description: string
  currency: ProductCategoryEnum
  created: number
  amount: number
  receipt_url: string
  source: string
}

export interface IPaymentMethods {
  payment_methods: IMethod[]
}

export interface IMethod {
  id: string
  is_default: boolean
  brand: string
  last4: string
}
