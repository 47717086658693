import { PAGE_SCREEN_VIEW_TRACKER } from '../constants/screen'

/**
 * Retrieves the screen name based on the provided path name.
 * @param pathname - current URL's pathname
 * @returns screen name
 */
export function getScreenPageName({ pathname }: { pathname: string }): string {
  const paths = pathname.split('/').filter(path => path !== '')

  if (paths.length === 0) {
    return PAGE_SCREEN_VIEW_TRACKER['']
  }

  const screenViewKey = paths.at(-1) as keyof typeof PAGE_SCREEN_VIEW_TRACKER
  return PAGE_SCREEN_VIEW_TRACKER[screenViewKey] || ''
}
