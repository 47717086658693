'use client'
import { TRACKER_EVENTS } from '@paladise/tracker/constants/event'
import { cn } from '@paladise/ui/lib/utils'
import { useTranslations } from 'next-intl'
import Link from 'next/link'
import { env } from 'store/server-context/static'

interface Props {
  text?: string | JSX.Element
  className?: string
}

export const Rules = ({ text, className }: Props) => {
  const t = useTranslations()
  return (
    <Link
      href={`${env.CONSUMER_BASE_URL}/palup-rules`}
      className={cn('text-label-l1', className)}
      target="_blank"
    >
      {text ? text : t('ethical_rules')}
    </Link>
  )
}

export const UserTerms = ({ text, className }: Props) => {
  const t = useTranslations()
  return (
    <Link
      href={`${env.CONSUMER_BASE_URL}/user-terms-of-service`}
      className={cn('text-label-l1', className)}
      target="_blank"
      data-tracker-click={TRACKER_EVENTS.text_usertermsofservice_click}
    >
      {text ? text : t('user_terms_of_service')}
    </Link>
  )
}

export const PrivacyPolicy = ({ text, className }: Props) => {
  const t = useTranslations()
  return (
    <Link
      href={`${env.CONSUMER_BASE_URL}/privacy-policy`}
      className={cn('text-label-l1', className)}
      target="_blank"
    >
      {text ? text : t('privacy_cookies')}
    </Link>
  )
}
