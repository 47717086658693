'use client'
import React, { useState, useTransition } from 'react'
import ThirdPartyAuth from './ThirdPartyAuth'
import { Button } from '@paladise/ui/components/ui/button'
import LoginForm from './LoginForm'
import { TextField } from '@paladise/ui/components/TextField'
import { useTranslations } from 'next-intl'
import { z } from 'zod'
import { AccountCheckSchema } from '../schema'
import { checkEmailAvailability } from 'features/account/utils/checkEmailAvailability'
import DividerWithText from 'components/DividerWithText'
import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'

interface Prop {
  onSignUp: () => void
}

function Login({ onSignUp }: Prop) {
  const [email, setEmail] = useState('')
  const [isLoading, setIsLoading] = useState(false)

  const t = useTranslations()
  const {
    formState: { errors },
    register,
    setError,
    handleSubmit,
  } = useForm<z.infer<typeof AccountCheckSchema>>({
    resolver: zodResolver(AccountCheckSchema),
    mode: 'onChange',
  })

  const handleNext = async ({ email }: z.infer<typeof AccountCheckSchema>) => {
    setIsLoading(true)
    try {
      const available = await checkEmailAvailability(email)
      if (available) {
        setError('email', { message: 'Account does not exist.' })
        return
      }
      setEmail(email)
    } catch (error) {
      console.log('🚀 ~ handleNext ~ error:', error)
    } finally {
      setIsLoading(false)
    }
  }

  if (email) return <LoginForm email={email} />

  return (
    <div className="min768:w-[300px] mx-auto h-full w-full">
      <p className="text-label-l1 text-title mb-2">{t('log_in_for_palup')}</p>
      <p className="text-label-l2 text-body mb-8">
        {t('log_in_promotion_message')}
      </p>
      <ThirdPartyAuth />
      <DividerWithText className="mb-8 mt-6" text={t('or')} />

      <form
        className="text-callout text-label-l1 space-y-4"
        onSubmit={handleSubmit(handleNext)}
      >
        <TextField
          label={t('email')}
          {...register('email', { required: true })}
          name={'email'}
          isInvalid={!!errors.email}
          errorMessage={errors.email?.message}
          placeholder={t('enter_email')}
        />
        <Button
          type="submit"
          className="bg-background-2nd border-label-l3 text-label-l1 text-callout mb-4 h-12 w-full border"
          loadingClassName="text-label-l1"
          loading={isLoading}
          disabled={isLoading}
        >
          {t('next')}
        </Button>
      </form>

      <p className="text-label-l1 text-caption">
        {t.rich('have_not_account_web', {
          t1: () => (
            <Button
              variant="text"
              className="text-subtitle text-primary-p1 ml-1 p-0"
              onClick={onSignUp}
            >
              {t('sign_up')}
            </Button>
          ),
        })}
      </p>
    </div>
  )
}

export default Login
