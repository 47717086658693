import type { GeoPoint } from '@paladise/tracker/types'
import type {
  IUser,
  Profile,
  TermsData,
  UserInfo,
} from 'features/account/types'
import { IBusinessInfo } from 'features/avatarProfile/type'
import type { Fetch } from 'lib/fetch/type'
import type { IResp, IRespV2 } from 'type/common'
import { generateParams } from 'utils/generate-params'

export const getUserByUserId = async (
  fetcher: Fetch,
  id: string,
  language?: string,
): Promise<IResp<IUser>> => {
  const queryParams = generateParams({
    language: language || '',
  })
  const res = await fetcher(fetcher.API.GW + `/v1/users/${id}?${queryParams}`)
  return res
}

export const getUser = async (fetcher: Fetch): Promise<IRespV2<UserInfo>> => {
  const res = await fetcher(fetcher.API.GW + `/v1/users/me/profile`)
  return res
}

export const getUserLocation = async (
  fetcher: Fetch,
): Promise<IResp<GeoPoint>> => {
  const res = await fetcher(fetcher.API.GW + `/v1/users/me/location`)

  return res
}

export const checkUserEmail = async (
  fetcher: Fetch,
  email: string,
): Promise<IResp<{ available: boolean }>> => {
  const res = await fetcher(
    fetcher.API.GW + `/v1/users/email-availability?email=${email}`,
  )

  return res
}

export const checkUsername = async (
  fetcher: Fetch,
  username: string,
): Promise<IResp<{ available: boolean }>> => {
  const res = await fetcher(
    fetcher.API.GW + `/v1/users/name-availability?name=${username}`,
  )

  return res
}

export const deleteUser = async (
  fetcher: Fetch,
  body: { message: string },
): Promise<IRespV2<string>> => {
  const res = await fetcher(fetcher.API.GW + `/v1/user`, {
    method: 'DELETE',
    body,
  })

  return res
}

export const updateUserProfile = async (
  fetcher: Fetch,
  profile: Profile,
): Promise<IResp<string>> => {
  const res = await fetcher(fetcher.API.GW + '/v1/users/me/profile', {
    method: 'PATCH',
    body: JSON.stringify(profile),
  })

  return res
}

export const changeUserPassword = async (
  fetcher: Fetch,
  data: {
    current_password: string
    new_password: string
  },
): Promise<IRespV2<string>> => {
  const res = await fetcher(fetcher.API.GW + '/v1/users/me/password', {
    method: 'PATCH',
    body: JSON.stringify(data),
  })

  return res
}

export const resetUserPassword = async (
  fetcher: Fetch,
  data: {
    email: string
  },
): Promise<IResp<string>> => {
  const res = await fetcher(fetcher.API.GW + '/v1/users/password/reset', {
    method: 'POST',
    body: JSON.stringify(data),
  })

  return res
}

export const resetUserPasswordCallback = async (
  fetcher: Fetch,
  data: {
    token: string
    password: string
  },
): Promise<IResp<string>> => {
  const res = await fetcher(
    fetcher.API.GW + '/v1/users/password/reset/validation',
    {
      method: 'POST',
      body: JSON.stringify(data),
    },
  )

  return res
}

export const sendUserVerificationEmail = async (
  fetcher: Fetch,
): Promise<IRespV2<string>> => {
  const res = await fetcher(fetcher.API.GW + '/v1/users/email/verification', {
    method: 'POST',
  })

  return res
}

export const getTermsData = async (fetcher: Fetch, type: string) => {
  let isError: boolean = false
  try {
    const query = new URLSearchParams()
    query.append('subject', type)
    const { data }: IResp<TermsData> = await fetcher(
      fetcher.API.GW + `/v1/terms?${query}`,
    )

    if ('error' in data || !data.created_at) isError = true
    return { data, isError }
  } catch (error) {
    console.log('🚀 ~ getTermsData ~ error:', error)
    isError = true
    return { isError }
  }
}

export const getBusinessInfo = async ({
  fetcher,
  user_id = '',
  company_id = '',
}: {
  fetcher: Fetch
  user_id?: string
  company_id?: string
}): Promise<IRespV2<IBusinessInfo>> => {
  const queryParams = generateParams({
    user_id,
    company_id,
  })

  const res = await fetcher(
    fetcher.API.GW + `/v1/companies/search?${queryParams}`,
    {
      method: 'GET',
    },
  )
  return res
}

export const updateUserTimeZone = async (fetcher: Fetch, time_zone: string) => {
  try {
    const res = await fetcher(fetcher.API.GW + '/v1/users/me/timezone', {
      method: 'PATCH',
      body: JSON.stringify({ time_zone }),
    })
    return res
  } catch (error) {
    return { error }
  }
}
