'use client'

import Link from 'next/link'
import { usePathname } from 'next/navigation'

const NavLink = ({
  href,
  children,
}: {
  href: string
  children: React.ReactNode
}) => {
  const pathname = usePathname()
  const isActive = pathname === href

  return (
    // when active, show 2px height shadow on the bottom offset 6px
    <Link
      href={href}
      data-active={isActive}
      className="text-callout text-l-l1 after:bg-primary-p1 relative after:absolute after:bottom-[-6px] after:left-0 after:h-[2px] after:w-full after:transition-colors data-[active=false]:after:opacity-0 data-[active=true]:after:opacity-100"
    >
      {children}
    </Link>
  )
}

export default NavLink
