import { z } from 'zod'

export const EmailSchema = z
  .string({ required_error: 'required' })
  .min(1, { message: 'required' })
  .email({
    message: 'email_incorrect_format_title',
  })

export const PasswordSchema = z
  .string({ required_error: 'required' })
  .min(6, { message: 'password_need_6_char' })

export const UsernameSchema = z.string({ required_error: 'Required' }).min(1, {
  message: 'required',
})
